<script setup lang="ts">
const props = withDefaults(defineProps<{ options?: IntersectionObserverInit }>(), {
  options: () => ({
    root: null,
    threshold: 0,
  }),
});

const emit = defineEmits<{
  (e: 'intersect'): void;
  (e: 'pin'): void;
}>();

const root = ref<HTMLDivElement | null>(null);
const observer = ref<IntersectionObserver | null>(null);

onMounted(() => {
  observer.value = new IntersectionObserver(([entry]) => {
    if (entry && entry.isIntersecting) {
      emit('intersect');
    }

    if (entry.intersectionRatio < 1) {
      emit('pin');
    }
  }, props.options);

  observer.value.observe(root.value as HTMLDivElement);
});

onUnmounted(() => {
  (observer.value as IntersectionObserver).disconnect();
});
</script>

<template>
  <div ref="root" />
</template>
